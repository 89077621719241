import React from 'react';
import Button from 'react-bootstrap/Button';
import { PriceCard } from 'components/PriceCard';
import { LessonType } from 'constants/courses';
import { IPackage } from 'types/price';

export interface IProps {
  pack: IPackage;
  currency: string;
  variant: 'primary' | 'secondary' | 'danger';
}

const lessonTypeToTextMap: { [key in LessonType]: string } = {
  [LessonType.INDIVIDUAL]: 'индивидуальных',
  [LessonType.GROUP]: 'индивидуально-групповых',
};

const getPaymentName = (
  packageSize: number,
  lessonType: LessonType,
  lessonDurationMin: number,
) =>
  `Пакет из ${packageSize} ${lessonTypeToTextMap[lessonType]} занятий по ${lessonDurationMin} минут`;

export const PriceRow = ({ pack, currency, variant }: IProps) => (
  <div className="row price-row">
    {pack.prices.map((packagePrice, index) => {
      const packageSize = 4 * (index + 1);
      return (
        <div key={index} className="col-lg-6 col-xxl-4 price-col">
          <PriceCard
            currency={currency}
            price={packagePrice}
            packageSize={packageSize}
            variant={variant}
            customActionBtn={
              <form action="https://chess-up.by/payment-processing">
                <input type="hidden" name="currency" value={currency} />
                <input
                  type="hidden"
                  name="name"
                  value={getPaymentName(
                    packageSize,
                    pack.lessonType,
                    pack.lessonDurationMin,
                  )}
                />
                <input type="hidden" name="price" value={packagePrice} />
                <Button
                  type="submit"
                  size="lg"
                  variant="secondary"
                  className="action-btn"
                >
                  Оплатить
                </Button>
              </form>
            }
          />
        </div>
      );
    })}
  </div>
);
