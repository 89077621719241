import React from 'react';
import { SEO } from 'components/SEO';
import { PriceBlock } from './PriceBlock';
import './styles.scss';

const Home = () => {
  return (
    <main className="page chess-pay-page container">
      <SEO title="Оплата шахматы" />
      <PriceBlock />
    </main>
  );
};

export default Home;
